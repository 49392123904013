import './App.scss';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Landing from './landing/Landing';
import Signup from "./signup/Signup"
import Login from "./login/Login"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
