import "./Landing.scss";
import logo from "../assets/logo.png";
import girlboss from "../assets/girlboss.png";
import { Link } from "react-router-dom";

const Landing = () => {
	return (
		<div className="landing">
			<div className="header">
				<div className="branding">
					<img src={logo} alt="" />
					<p>SlaveTrade</p>
				</div>

				<div className="user">
					<Link to="/login" style={{ paddingRight: "1vw" }}>Log In</Link>
					<Link to="/signup">Sign Up</Link>
				</div>
			</div>

			<div className="main">
				<div className="content">
					<p className="motto">The Future of Slavery is here</p>
					<p className="description">
						Slavestore is the leading innovator in buying, selling and trading slaves.
						Proudly serving our white masters since 1951.
					</p>
					<Link to="/shop" className="getstarted">
						Get Started
					</Link>
				</div>
				<img src={girlboss} alt="" />
			</div>
		</div>
	);
};

export default Landing;
